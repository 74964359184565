<template>
  <div>
    <div class="loginPage">
      <div class="loginWhiteBox">
        <div class="loginLogoBox">
          <img src="@/assets/images/yeet/yeet_logo.png" alt="">
        </div>
        <div class="btnLoaderCtm" v-if="emailVerification.loader">
          <div class="item loading-6">
            <svg viewBox="25 25 50 50">
              <circle cx="50" cy="50" r="20"></circle>
            </svg>
          </div>
        </div>
        <div v-else-if="!emailVerification.loader && emailVerification.isExpired">
          <div class="loginTitlebox">
            <h4>Email Link Has Been Expired</h4>
            <div class="loginFormBox">
              <div class="col-md-12">
                <div class="forgotPasswordBtn center" v-if="$cookies.get('meri_web_shop.current_user')">
                  <router-link to="/">Return to Dashboard</router-link>
                </div>
                <div class="forgotPasswordBtn center" v-else-if="isStoreExists">
                  <router-link to="/login">Return to Login</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="!emailVerification.loader && emailVerification.isAlreadyVerified">
          <div class="loginTitlebox">
            <h4>Email Has Already Been Verified</h4>
            <div class="loginFormBox">
              <div class="col-md-12">
                <div class="forgotPasswordBtn center" v-if="$cookies.get('meri_web_shop.current_user')">
                  <router-link to="/">Return to Dashboard</router-link>
                </div>
                <div class="forgotPasswordBtn center" v-else-if="isStoreExists">
                  <router-link to="/login">Return to Login</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="!emailVerification.loader && emailVerification.isVerified">
          <div class="loginTitlebox">
            <h4>Email Verified Successfully</h4>
            <div class="loginFormBox">
              <div class="col-md-12">
                <div class="forgotPasswordBtn center" v-if="$cookies.get('meri_web_shop.current_user')">
                  <router-link to="/">Return to Dashboard</router-link>
                </div>
                <div class="forgotPasswordBtn center" v-else-if="isStoreExists">
                  <router-link to="/login">Return to Login</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="loginTitlebox">
            <h4>Something went wrong. Please try later.</h4>
            <div class="loginFormBox">
              <div class="col-md-12">
                <div class="forgotPasswordBtn center" v-if="$cookies.get('meri_web_shop.current_user')">
                  <router-link to="/">Return to Dashboard</router-link>
                </div>
                <div class="forgotPasswordBtn center" v-else-if="isStoreExists">
                  <router-link to="/login">Return to Login</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { MessageBox, Message } from "element-ui";
import { mapGetters } from "vuex";

export default {
  name:'EmailVerification',
  metaInfo() {
    return {
      title: 'Email Verification | YeetCommerce',
    };
  },
  data:()=>({
    token:'',
    isLoading:false,
    emailVerification:{
      isVerified:false,
      loader:false,
      isAlreadyVerified:false,
      isExpired:false,
    },
    isStoreExists:false,
    isNewUser:false,

  }),
  computed:{
    ...mapGetters({
      logoutIsPending:'logoutIsPending',
    }),
  },
  methods: {
    alreadyLoggedInHandle(){

      MessageBox.confirm(
        "An account is already logged in.",
        "Note",
        {
          type: "warning",
          confirmButtonText: "Logout",
          cancelButtonText: "Continue using it",
          showClose:false,
          closeOnClickModal:false,
          beforeClose:(action, instance, done)=>{

            if(action === 'confirm'){

              instance.confirmButtonLoading = true;
              instance.confirmButtonText = 'Loading...';

              this.$store.dispatch('logoutWithoutConfirmation').then(()=>{
                
                instance.confirmButtonLoading = false;
                instance.confirmButtonText = 'Logout';

                done();

              });

            }else{

              done();

              this.$router.push({path: '/'});

            }

          }

        }
      ).then(async () => {
        
      }).catch(()=>{

        this.$router.push({path: '/'});

      });
    },
  },
  mounted(){

    window.addEventListener('beforeunload', (event) => {

      if(this.token){

        event.returnValue = "Are you sure you want to leave?";

      }

    });

  },
  async beforeMount(){

    if(this.$route.query.token && this.$route.query.email){

      this.token = this.$route.query.token;

      let formData = new FormData();

      formData.append('email',this.$route.query.email);
      formData.append('token',this.token);
      if(this.$route.query.admin){

        formData.append('isAdmin',1);
        formData.append('previous_email',this.$route.query.admin_email);
        formData.append('store_id',this.$route.query.store);

      }
      // if(this.$route.query.newUser){
      //   this.isNewUser = true
      // }

      this.emailVerification.loader = true;
      try{
        let res = await this.$axios.post('/email/verification/link/verify',formData);
        if(res.data.status_code == "1146"){

          this.emailVerification.loader = false;
          this.emailVerification.isVerified = true;

          this.$notify({
            type: 'success',
            title: 'Success',
            message: res.data.message,
          });

          if(this.$cookies.get('meri_web_shop.current_user')){

            let loggedInUser = this.$cookies.get('meri_web_shop.current_user');

            if(loggedInUser.email == this.$route.query.email){

              this.$router.push({path: '/'});

            }else{

              this.alreadyLoggedInHandle();

            }

          }else{

            this.$router.push({path: '/login'});

          }

        }
      }catch(error){

        this.emailVerification.loader = false;
        this.emailVerification.isVerified = false;

        if(error.response){

          if(error.response.data.error.includes("expired")){

            this.emailVerification.isExpired = true;

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.error,
            });

          }else if(error.response.data.error.includes('verified')){

            this.emailVerification.isAlreadyVerified = true;

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.error,
            });

          }else if (error.response.data.error.includes("Unable")){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.error,
            });

          }

        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });

        }
        
      }


      formData.append('email',this.$route.query.email)

      try{
        let storeRes = await this.$axios.get('/store/check',{
          params:{
            email: this.$route.query.email
          }
        });
        if(storeRes.data.status_code == "100"){

          this.isStoreExists = true;

        }else if(storeRes.data.status_code == "2020"){

          this.isStoreExists = false;

        }
      }catch(error){

        if(error.response){

           this.$message({
            type: 'error',
            showClose: true,
            message: error.response.data.message,
          });
          
        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });

        }
       
      }

    }else{

      this.$router.push({path: '/login'});

    }
    
  },
}
</script>

<style scoped>
  /* email verification New Page Css Start Here */

  .loginPage{
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    padding: 50px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1b3561;
    background: -moz-linear-gradient(-45deg,  #1b3561 0%, #25799d 100%);
    background: -webkit-linear-gradient(-45deg,  #1b3561 0%,#25799d 100%);
    background: linear-gradient(135deg,  #1b3561 0%,#25799d 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1b3561', endColorstr='#25799d',GradientType=1 );
  }
  .loginWhiteBox{
    max-width: 652px;
    width: 100%;
    background-color: #fff;
    padding: 59px 44px 58px;
    box-shadow: 0 3px 15px 0 rgb(0 0 0 / 24%);
  }
  .loginLogoBox{
    width: 160px;
    margin: 0 auto;
    margin-bottom: 92px;
  }
  .loginLogoBox a{
    display: block;
  }
  .loginLogoBox img{
    width: 100%;
  }
  .loginTitlebox{
    text-align: center;
    margin-bottom: 33px;
  }
  .loginTitlebox h4{
    font-size: 24px;
    color: #000000;
    margin-bottom: 11px;
    font-weight: 700;
  }
  .loginTitlebox p{
    font-size: 14px;
    color: #9E9B9B;
    margin-bottom: 0px;
    font-weight: 400;
  }
  .loginFormBox .formType label{
    font-size: 18px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 10px;
  }
  .loginFormBox .formType label span{
    color: #FD4D5D;
  }
  .loginFormBox .formType input{
    border: 1px solid #e8e8e8 !important;
    background-color: #FCFCFC !important;
    font-size: 16px;
    font-weight: 400;
    color: #000;
    border-radius: 4px;
  }
  .loginFormBox .formType .text-danger{
    font-size: 13px;
    color: #dc3545;
    font-style: italic;
  }
  .text-danger{
    font-size: 13px;
    color: #dc3545;
    font-style: italic;
  }
  .formType.checkRememberBox{
    display: flex;
    align-items: center;
  }
  .formType.checkRememberBox input{
    border-radius: 0px !important;
    width: 17px;
    height: 17px;
    margin-right: 10px;
  }
  .formType.checkRememberBox label{
    margin-bottom: 0px !important;
    font-size: 16px;
    font-weight: 400;
  }
  .forgotPasswordBtn{
    text-align: right;
  }
  .forgotPasswordBtn.center{
    text-align: center;
    margin-top: 10px;
  }
  .forgotPasswordBtn a{
    font-size: 16px;
    font-weight: 400;
    color: #000;
    display: inline-block;
  }
  .loginFormBox .row{
    margin-bottom: 41px;
  }
  .loginFormBox .formSubmitBtn{
    text-align: center;
    margin-bottom: 20px;
  }
  .loginFormBox .formSubmitBtn button{
    width: 60%;
    margin: 0 auto;
    font-weight: 700;
  }
  .formBottomTxt{
    text-align: center;
  }
  .formBottomTxt p{
    font-size: 14px;
    color: #9E9B9B;
    font-weight: 400;
    margin-bottom: 30px;
  }
  .formBottomTxt p a{
    color: #22678D;
    display: inline-block;
    text-decoration: none;
  }
  .formBottomTxt h4{
    color: #9E9B9B;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 0px;
  }
  .formBottomTxt h4 button{
    color: #22678D;
    background-color: transparent;
    padding: 0px;
    border-radius: 0px;
    border: none;
  }
  .formSubmitBtn button {
    width: 100%;
    background-color: #14223d !important;
    border: none !important;
    color: #fff;
    padding: 10px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .formSubmitBtn button:hover {
    background-color: #fd4e5d !important;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  /* email verification New Page Css End Here */
</style>